import React from 'react'

const Welcome = (props) => {
  const register = () => {
    localStorage.removeItem('deviceToken');
    props.setScreenState(2);

}
  return (
    <>
        <h1 className='font-extrabold text-5xl' style={{ color: '#F76B5B' }}>OLTIME DEVICE REGISTRATION</h1>
        {/* <p className='font-light mt-5' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam ea <br />exercitationem dolor et voluptates perferendis illum eligendi dignissimos recusandae distinctio.</p> */}
        <button onClick={()=> register()} className='px-5 py-3 font-semibold rounded-lg text-white text-2xl mt-16 shadow-lg' style={{ backgroundColor: '#F76B5B' }}>REGISTER NOW</button>
    </>
  )
}

export default Welcome