import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';


const Camera = (props) => {

    const videoRef = useRef(null);
    const photoRef = useRef(null);
    // const [captured, setCaptured] = useState(false);
    const [counter, setCounter] = useState(7);
    // eslint-disable-next-line
    const [isSendingSuccess, setIsSendingSuccess] = useState(false);
    const before = () => {
        setCounter(counter - 1);
    }
    const after = () => {
        setCounter(counter + 1);
    }
    // watch for counter changes
    useEffect(() => {
        const timer = setTimeout(() => setCounter(counter - 1), 500)
        if (counter === 0) {

            takePhoto();
        }
        if (counter === -1) {
            clearTimeout(timer);
            sendPhoto();
        }
        if (counter === -4) {
            props.setScreenState(3);
        }
        console.log(counter);
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);






    const getVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })

            .then(async (stream) => {
                videoRef.current.srcObject = stream;
                await videoRef.current.play();
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const stopCamera = () => {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    useEffect(() => {
        getVideo();
    }, [videoRef]);
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    const sendPhoto = async () => {
        try {
            const canvas = photoRef.current;
            const data = canvas.toDataURL('image/png');
            // console.log(data);
            const cleanBase64 = data.replace(/^data:image\/(png|jpg);base64,/, '');
            // console.log(cleanBase64);
            let dataForm = new FormData();
            const blob = b64toBlob(cleanBase64, 'image/png');
            const blobUrl = URL.createObjectURL(blob);

            const img = document.createElement('img');
            img.src = blobUrl;
            dataForm.append('image', blob, 'image.png');
            // dataForm.append('token', '5;1;7b66aab01094df3fb03728a445755d74');
            dataForm.append('token', localStorage.getItem('deviceToken'));
            dataForm.append('attendance_id', props.userState.userId);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/device/send-image`, dataForm,
                {
                    headers: {
                        'Content-Type': `multipart/form-data boundary=${data._boundary}`
                    }
                }
            )
            console.log(response);
            props.setUserState({ userId: null })
            // eslint-disable-next-line 
            if (response.data.status == 1) {
                setIsSendingSuccess(true);
                setCounter(counter - 1);
            } else {
                setIsSendingSuccess(false);
                setCounter(counter - 1);
            }
        } catch (error) {
            console.error(error);
            props.setUserState({ userId: null })
            setIsSendingSuccess(false);
            setCounter(counter - 1)
        }

        // setCaptured(true);
    }
    const takePhoto = () => {
        const canvas = photoRef.current;
        const context = canvas.getContext('2d');
        const width = videoRef.current.videoWidth;
        const height = videoRef.current.videoHeight;
        canvas.width = width;
        canvas.height = height;
        context.drawImage(videoRef.current, 0, 0, width, height);
        stopCamera();
        // sendPhoto();
    }



    return (
        <div className={`flex flex-col transition-all duration-1000 delay-100 ${counter < -2 ? 'opacity-0 translate-y-20' : ''}`}>
            <div className="mx-auto relative items-center flex">

                <div className={`spin mx-auto transition-all duration-1000 ${counter >= 7 ? 'opacity-0 translate-y-20' : 'translate-y-64'}`}></div>
            </div>
            <div className={`relative w-full flex-col mx-auto transition-all duration-200 ${counter < 6 ? '' : 'opacity-0'}`} style={{ height: '500px', maxHeight: '500px' }}>
                <video className='' ref={videoRef}></video>
                <div className="absolute h-full top-0 bottom-0 left-0 right-0 z-50 flex justify-center text-center items-center overflow-hidden">
                    {/* <h1 className={`absolute m-auto z-50 font-bold transition-all duration-1000 ${counter > 5 ? 'opacity-0' : counter === 5 ? 'opacity-100' : 'opacity-0'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > 5 ? '0px' : counter === 5 ? '200px' : '900px'}` }}>5</h1>
                    <h1 className={`absolute m-auto z-50 font-bold transition-all duration-1000 ${counter > 4 ? 'opacity-0' : counter === 4 ? 'opacity-100' : 'opacity-0'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > 4 ? '0px' : counter === 4 ? '200px' : '900px'}` }}>4</h1>
                    <h1 className={`absolute m-auto z-50 font-bold transition-all duration-1000 ${counter > 3 ? 'opacity-0' : counter === 3 ? 'opacity-100' : 'opacity-0'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > 3 ? '0px' : counter === 3 ? '200px' : '900px'}` }}>3</h1>
                    <h1 className={`absolute m-auto z-50 font-bold transition-all duration-1000 ${counter > 2 ? 'opacity-0' : counter === 2 ? 'opacity-100' : 'opacity-0'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > 2 ? '0px' : counter === 2 ? '200px' : '900px'}` }}>2</h1>
                    <h1 className={`absolute m-auto z-50 font-bold transition-all duration-1000 ${counter > 1 ? 'opacity-0' : counter === 1 ? 'opacity-100' : 'opacity-0'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > 1 ? '0px' : counter === 1 ? '200px' : '900px'}` }}>1</h1> */}
                    {/* <h1 className={`absolute m-auto z-50 font-bold transition-all duration-500 ${counter > -1 ? 'opacity-0 ' : counter === -1 ? 'opacity-100' : 'opacity-0 translate-y-20'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > -1 ? '50px' : counter === -1 ? '50px' : '50px'}` }}>Sending your photo...</h1> */}
                    {
                        // isSendingSuccess ?
                        //     <h1 className={`absolute m-auto z-50 font-bold transition-all duration-500 ${counter > -2 ? 'opacity-0 -translate-y-20' : counter === -2 ? 'opacity-100 ' : 'opacity-0 translate-y-20'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > -2 ? '50px' : counter === -2 ? '50px' : '50px'}` }}>OK</h1>
                        //     :
                        //     <h1 className={`absolute m-auto z-50 font-bold transition-all duration-500 ${counter > -2 ? 'opacity-0 -translate-y-20' : counter === -2 ? 'opacity-100 ' : 'opacity-0 translate-y-20'}`} style={{ color: '#fff', lineHeight: '10px', fontSize: `${counter > -2 ? '50px' : counter === -2 ? '50px' : '50px'}` }}>ERROR</h1>
                    }

                </div>
                <canvas className={`absolute top-0 bottom-0 left-0  ${photoRef ? 'z-10' : 'z-0'}`} ref={photoRef}></canvas>
            </div>
            {/* <div className="w-full flex-col mx-auto">
            </div> */}

            <div className="w-full hidden">
                <button onClick={() => stopCamera()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg mr-10' style={{ backgroundColor: '#F76B5B' }}>Stop</button>
                <button onClick={() => takePhoto()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg mr-10 ' style={{ backgroundColor: '#F76B5B' }}>sendPhoto</button>
                <button onClick={() => before()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg mr-10 ' style={{ backgroundColor: '#F76B5B' }}>before</button>
                <button onClick={() => after()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg mr-10 ' style={{ backgroundColor: '#F76B5B' }}>after</button>
            </div>


        </div>
    )
}

export default Camera