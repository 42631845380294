import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';


import flipIcon from '../assets/images/flip-icon.svg';
import { fetchToken } from '../firebase';
import axios from 'axios';


const QRRegister = (props) => {
    const [qrData, setQRData] = useState('No result');
    const [cameraDirection, setCameraDirection] = useState('environment');
    const [stillRequest, setStillRequest] = useState(false);
    const [firebaseId, setFirebaseId] = useState(null);
    // const [delayScan, setDelayScan] = useState(1000);
    const changeCameraDirection = () => {
        if (cameraDirection === 'environment') {
            setCameraDirection('user');
        } else {
            setCameraDirection('environment');
        }
        console.log(cameraDirection);
    }
    const stillRequestCooldown = () => {
        // setStillRequest(true);
        setTimeout(() => {
            setStillRequest(false);
        }, 1000);
    }
    const isDeviceRegisteredCheck = async () => {
        try {
            localStorage.getItem('deviceToken') ? props.setScreenState(3) : props.setScreenState(2);
        } catch (error) {

        }
    }

    const request = async () => {
        try {
            setStillRequest(true);
            // console.log('requesting...');
            const url = process.env.REACT_APP_API_URL;
            // const url = 'http://localhost:4000'
            const response = await axios.post(`${url}/api/device/register-device`, {
                "code": qrData,
                // "code": 'NTsxOzIwMjItMDktMTcgMTQ6NTE6MjQ=',
                "firebase_id": firebaseId
            },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            // console.log(response);
            // console.log('=======================')
            // eslint-disable-next-line 
            if (response.data.status == 1) {

                const deviceToken = await response.data.data.token;
                localStorage.setItem("deviceToken", deviceToken);
          
                props.setScreenState(3);
                const responseData = await response.data.data;
                const responseDataToString = JSON.stringify(responseData);
                localStorage.setItem("deviceData", responseDataToString);
                window.location.reload(false);
            } else {
                
          
                props.setScreenState(2);
                window.location.reload(false);
                stillRequestCooldown();
            }

            stillRequestCooldown();
        } catch (error) {
            console.log(error);

            stillRequestCooldown();
        }
    }
    const testRequest = async () => {
        try {
            setStillRequest(true);
            // console.log('requesting...');
            // const url = process.env.REACT_APP_API_URL;
            const url = 'http://localhost:4000'
            const response = await axios.post(`${url}/api/device/register-device`, {
                // "code": qrData,
                "code": 'NTsxOzIwMjItMDktMTcgMTQ6NTE6MjQ=',
                "firebase_id": firebaseId
            },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            console.log(response);

            // eslint-disable-next-line 
            if (response.data.status == 1) {

                const deviceToken = await response.data.data.token;
                localStorage.setItem("deviceToken", deviceToken);
          
                props.setScreenState(3);
                window.location.reload(false);
            } else {
                
          
                props.setScreenState(2);
                window.location.reload(false);
                stillRequestCooldown();
            }

            stillRequestCooldown();
        } catch (error) {
            console.log(error);

            stillRequestCooldown();
        }
    }


    // const closeCam = async () => {

    //     console.log(delayScan);

    //     setDelayScan(false);
    //     setCameraDirection('user');

    // };

    useEffect(() => {
        if (qrData !== 'No result') {
            setTimeout(function () {
                console.log('delay 1 detik')
                if (!stillRequest) {
                    request();
                }
            }, 1000);

        }
        // no eslint warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrData])
    useEffect(() => {
        isDeviceRegisteredCheck();
        fetchToken().then((data) => {
            if (data) {
                setFirebaseId(data);
                console.log(data)
            }
        })

 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-11/12 md:w-8/12 md:mt-10 scan'>
            {
                cameraDirection === 'environment' ?
                    <QrReader
                        // ref={ref}
                        key='environment'
                        constraints={{ facingMode: 'environment' }}
                        onResult={(result, error) => {
                            if (!!result) {
                                setQRData(result?.text);
                            }

                            if (!!error) {
                                setQRData('No result');
                                // console.info(error);
                            }
                        }}

                        style={{ width: '100%' }}


                    />
                    : cameraDirection === 'user' ?
                        <QrReader
                            key='user'
                            constraints={{ facingMode: 'user' }}
                            onResult={(result, error) => {
                                if (!!result) {
                                    setQRData(result?.text);
                                }

                                if (!!error) {
                                    setQRData('No result');
                                    // console.info(error);
                                }
                            }}

                            style={{ width: '100%' }}
                        // ref={ref}

                        />
                        :
                        <div className="" key='null'></div>



            }
            <div id='qrCodeScanner' className=""></div>
            {/* <p>{qrData}</p>
            <p>{cameraDirection}</p> */}
            <div className="w-full flex justify-center">

                <button onClick={() => changeCameraDirection()} className='py-2 px-3 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg' style={{ backgroundColor: '#F76B5B' }}>
                    <img className='w-5 mr-2' src={flipIcon} alt="Flip Camera Icon" />
                    Flip Camera
                </button>
            </div>
            <div className="w-full flex justify-center mt-5 hidden">

                <button onClick={() => testRequest()} className='py-2  px-3 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg' style={{ backgroundColor: '#F76B5B' }}>
                    
                    Test
                </button>
            </div>
     

        </div>
    )
}

export default QRRegister
