import React, { useEffect } from 'react'
import backgroundImage from '../assets/images/background.png';

const Logout = () => {
    useEffect(() => {
        localStorage.removeItem('deviceToken');
        window.location.href = '/';
    }, []);
    return (
        <div className="flex w-full h-screen background justify-center items-center flex-col text-center roboto" style={{ backgroundImage: `url(${backgroundImage}) ` }}>
            Logout</div>
    )
}

export default Logout