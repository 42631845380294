import backgroundImage from './assets/images/background.png';
import maximize from './assets/images/maximize.png';
import minimize from './assets/images/minimize.png';
import './App.css';
import Welcome from './components/Welcome';
import QRRegister from './components/QRRegister';
import QRShow from './components/QRShow';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Camera from './components/Camera';
import Logout from './components/Logout';
import { useWakeLock } from 'react-screen-wake-lock';

function App() {
  const [userState, setUserState] = useState({});
  const [screenState, setScreenState] = useState(1);
  const [isFullScreen, setFullScreen] = useState(false);
  const { isSupported, request } = useWakeLock();

  const isDeviceRegisteredCheck = async () => {
    try {
      if (localStorage.getItem('deviceToken')) {
        setScreenState(3);
      } else {
        setScreenState(1);
      }
    } catch (error) {

    }
  };

  /* View in fullscreen */
  const openFullScreen = () => {
    var el = document.getElementById("main");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.webkitRequestFullscreen) { /* Safari */
      el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) { /* IE11 */
      el.msRequestFullscreen();
    }
  };

  /* Close fullscreen */
  const closeFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  };

  const handleFullScreen = () => {
    isFullScreen ? closeFullScreen() : openFullScreen();
    setFullScreen(!isFullScreen);
  };

  useEffect(() => {
    isSupported && request();
    window.addEventListener('focus', function () {
      isSupported && request();
    });
    isDeviceRegisteredCheck();
  }, []);


  return (
    <Router>
      <Routes>
        <Route path='/' element={
          <div id="main" className="flex w-full h-screen background justify-center items-center flex-col text-center roboto" style={{ backgroundImage: `url(${backgroundImage}) ` }}>
            <button onClick={handleFullScreen} className="absolute top-4 right-4 w-7">
              <img src={isFullScreen ? minimize : maximize} alt='toggle' width="100%" className="opacity-40"/>
            </button>
            {
              screenState === 1 ?
                <Welcome setScreenState={setScreenState} setUserState={setUserState} />
                : screenState === 2 ?
                  <QRRegister setScreenState={setScreenState} setUserState={setUserState} />
                  : screenState === 3 ?
                    <QRShow setScreenState={setScreenState} setUserState={setUserState} />
                    : screenState === 4 ?
                      <Camera setScreenState={setScreenState} userState={userState} setUserState={setUserState} />
                      : null
            }
          </div>
        } />
        <Route path='/logout' exac element={<Logout />} />
      </Routes>
    </Router>
  );
}

export default App;
