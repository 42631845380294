import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import scanMe from '../assets/images/scanme.svg';
import { onMessageListener } from '../firebase';
import axios from 'axios';

const QRShow = (props) => {
    const [test, setTest] = useState(false);
    const [count, setCount] = useState(5);
    const [qrCode, setQrCode] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const deviceData = JSON.parse(localStorage.getItem('deviceData'));
    const moveToCameraScreen = () => {
        setTest(!test);
        // make timeout
        setTimeout(() => {
            setTest(!test);
            props.setScreenState(4);
        }, 1200);

    }
    useEffect(() => {
        refreshQR();
        setTimeout(() => {

            setIsLoaded(true);
        }, 500);
        console.log(deviceData)
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => setCount(count - 1), 1000)
        if (count === 0) {
            clearTimeout(timer);
            setCount(5);
            refreshQR();
        }
        // console.log(count == 0 ? null : count);
        return () => clearTimeout(timer)
        // eslint-disable-next-line
    }, [count])
    const refreshQR = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/device/get-qrcode/${localStorage.getItem('deviceToken')}`)
            // console.log(response);
            setQrCode(response.data.qr_code);
            // eslint-disable-next-line
            if (!response.data.status == 1) {

                localStorage.removeItem('deviceToken');
                props.setScreenState(2);
                window.location.reload(false);
            }
        } catch (error) {
            props.setScreenState(2);
            localStorage.removeItem('deviceToken');
            console.log(error);
        }


    }
    const logout = () => {
        localStorage.removeItem('deviceToken');
        // props.setIsDeviceRegistered(false);
        props.setScreenState(1);
    }
    // const isDeviceRegisteredCheck = async () => {
    //     try {
    //         localStorage.getItem('deviceToken') ? props.setScreenState(3) : props.setScreenState(2);
    //     } catch (error) {

    //     }
    // }
    // onMessageListener().then(payload => {
    //     console.log(payload);
    //     props.setUserState({ userId: payload.notification.body })
    //     moveToCameraScreen()
    // }).catch(err => console.log('failed: ', err));




    return (
        <div className={`w-full flex flex-col justify-center items-center transition-all delay-200 duration-500 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}>
            <div className="mx-2 md:w-6/12">
                <h1 className={`text-xl md:text-5xl font-bold transition-all duration-1000 ${test ? 'mb-10 opacity-0' : ''}`} style={{ color: '#F76B5B' }}>{deviceData.name}</h1>
            </div>
            <div className={`transition-all duration-1000 delay-200 flex justify-center items-center ${test ? 'w-12 h-12 bg-red-400 translate-y-40 opacity-0' : 'w-1 h-1 opacity-0'}`}>
                <div className="relative">

                    <div className="spin"></div>
                </div>
            </div>
            <div className={`relative rounded-xl bg-white p-2 md:p-7 shadow-lg transition-all duration-1000 ${test ? 'mt-10 opacity-0' : ''}`}>
                <div className="absolute -bottom-14 right-8 md:-bottom-10 md:-left-44 ">
                    <img className="w-48" src={scanMe} alt="Scan me" />
                </div>
                {
                    qrCode ? <QRCode
                        title="title"
                        value={qrCode}
                        bgColor="#FFFFFF"
                        fgcolor="#000000"
                        level="L"
                        size={256}
                    /> : <div className="w-64 h-64 flex justify-center items-center">
                        <div className="relative ">
                            <div className="spin"></div>
                        </div>
                    </div>
                }


            </div>
            <div className="w-full  justify-center hidden">
                <button onClick={() => moveToCameraScreen()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg' style={{ backgroundColor: '#F76B5B' }}>
                    test
                </button>
            </div>
            <div className="w-full text-center mx-auto">
                <p className={`transition-all duration-300 ${test ? 'translate-y-20 opacity-0' : ''}`}>QR Code will refresh in {count} </p>
            </div>
            <div className={`w-full hidden justify-center ${test ? 'opacity-0' : ''}`}>
                <button onClick={() => logout()} className='py-2 px-3 mt-16 md:mt-5 flex items-center justify-center font-semibold rounded-lg text-white text-base  shadow-lg' style={{ backgroundColor: '#F76B5B' }}>
                    Logout
                </button>
            </div>

        </div>
    )
}

export default QRShow