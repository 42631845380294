import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyDgw3n7WSm4n4Qj1tUnXIfyZUeNjAlsl_k",
    authDomain: "oltime-89cee.firebaseapp.com",
    projectId: "oltime-89cee",
    storageBucket: "oltime-89cee.appspot.com",
    messagingSenderId: "299269717539",
    appId: "1:299269717539:web:95d9007ad3aac4125c7b96",
    measurementId: "G-YQS0VB2003"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// const app = initializeApp(firebaseConfig);
// export const requestForToken = async () => {
//     return getToken(messaging, { vapidKey: `BDIITzmH9R0-6cmAR3iqiAtMZEsZkRemsRur7zUGAZelA4hqjnATf-2o-APDN-NcCbNkKhOPR3Dr01bsQBXl490` })
//       .then((currentToken) => {
//         if (currentToken) {
//           console.log('current token for client: ', currentToken);
//           // Perform any other neccessary action with the token
//         } else {
//           // Show permission request UI
//           console.log('No registration token available. Request permission to generate one.');
//         }
//       })
//       .catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//       });
//   };

// export default app;
export const fetchToken = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BDIITzmH9R0-6cmAR3iqiAtMZEsZkRemsRur7zUGAZelA4hqjnATf-2o-APDN-NcCbNkKhOPR3Dr01bsQBXl490' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // setTokenFound(true);
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            return null;
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
  }
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });